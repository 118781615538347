
.bookNowBtn {
    width: auto;
    background: #ca4145;
    border-radius: 7px;
    padding: 12px 49px;
    color: white;
    font-weight: 300;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    font-size: 1rem;
  }

 

  @media only screen and (max-width: 992px) {

    .responsive{
        width: 100%;
        padding: 12px 0;
      }
      
    
}