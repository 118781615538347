.staffHeader {
  height: auto;
  background: linear-gradient(180deg, #963033 0%, #4a1819 35.42%);
  background-repeat: no-repeat;
  position: relative;
  text-align: center;

}

.imageContainer {
    position: absolute;
    left: 117px;
    top: 32px;
}


.staffContainer{
    top: -143px;
    padding-top: 70px;
    position: relative;
    z-index: 0;
}


