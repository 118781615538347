///LIST
.table-users {
  border-collapse: collapse;
  width: 80%;
  margin-top: 100px;
  background-color: #1f1e1e;
}

.table-users td {
  border: 1px solid #fff;
  padding: 8px;
  text-align: center;
}

.table-users th {
  background-color: #fff;
  color: #000;
  padding: 8px;
  text-align: center;
}

.table-users th:nth-child(1) {
  border-radius: 10px 0 0 0;
  
}

.table-users th:nth-child(4) {
  border-radius: 0 10px 0 0;
}

.table-users td button {
  border: none;
  cursor: pointer;
}

.tableHead {
  text-transform: uppercase;
  font-size: 15px;
}
