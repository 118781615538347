.infoItem {
  display: flex;
  margin: 40px 0;
  
}

.iconTitle {
  font-weight: 300;
  font-size: 22px;
}

.iconDescription {
  
  font-weight: 100;
  font-size: 14px;
  color: #b9b6b6;
}

.iconDescription a{
  
  font-weight: 100;
  font-size: 14px;
  color: #b9b6b6;
  text-decoration: none;
}

.iconText{
    margin-left: 25px;
}

.contactUsFormContainer{
    width: 40%;
}


@media only screen and (max-width: 992px) {

    .contactUsFormContainer{
      
        width: 100%;
    }
    
}