.socialNetworks ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
}
.socialNetworks ul li {
    margin: 0 18px;
}

.footerContainer {
    margin: 50px 0;
}

.footerContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.footerLogo, .footerText, .socialNetworks{
    margin: 15px 0
}