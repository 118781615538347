.topBarContainer {
  background-color: #0a0a0a;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 2;
}

.menuContainer ul {
  list-style-type: none;
  color: white;
}

.menuContainer ul li {
  display: inline-block;
  margin: 0 15px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-transform: uppercase;
  color: #959595;
  cursor: pointer;
}
