.infoTitle {
  font-weight: 400;
  font-size: 2rem;
  display: inline-block;
}

.infoTitle::after {
  content: "";
  height: 1px;
  display: block;
  margin-top: 13px;
  background-color: white;
}

.infoSubtitle {
  font-weight: 300;
  font-size: 16px;
  margin: 20px 0;
  color: #b9b6b6;
}

.infoContainer {
  position: absolute;
  top: 40px;
  left: 40px;
  opacity: 0;
}

.galleryContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.itemGallery {
  position: relative;
  margin: 20px 20px;
}

.itemGalleryImage {
  display: inline-block;
  min-width: 640px;
  min-height: 470px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.itemGallery:hover .infoContainer {
  opacity: 1;
  animation: fadeIn 5s;
  transition: 0.5s;
}

.itemGallery:hover {
  transform: scale(1.1);
  transition: 0.5s;
}

.itemGallery:hover .itemGalleryImage {
  opacity: 0.5;
}
.itemGalleryImage img {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 992px) {
  .infoContainer {
    position: relative;
    display: block;
    opacity: 1;
    padding: 20px;
    top: 0;
    left: 0;
  }
  .infoTitle::after {
    background: linear-gradient(to right, white 50%, #ca4145 0%);
  }
  .infoTitle {
    width: 100%;
  }

  .itemGalleryImage {
    min-width: 100%;
  }
}
