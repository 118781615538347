.formContainer {
    background-color: #ffffff;
    color: #000000;
    padding: 20px;
    text-align: center;
    max-width: 500px;
    box-shadow: 1px 1px 1px rgb(255, 255, 255);
    border-radius: 5px;
  }
  
  .fileUploadButton {
    display: none;
  }
  .label{
      display: inline-flex;
      cursor: pointer;
      align-items: center;
  }