.inputItem label {
  font-weight: 100;
  font-size: 22px;
  display: block;
}

.inputItem input {
  display: block;
  width: 100%;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid white;
  color: white;
  padding: 8px 0;
}

.contactForm {
  width: 60%;
}

.inputItem {
  margin-bottom: 50px;
}


@media only screen and (max-width: 992px) {

    .contactForm{
        width: 100%;
    }

    .contactUsFormContainer{
        width: 100%;
    }
    
}