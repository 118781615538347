.active {
  color: #ffffff;
  font-weight: 500;
}

.overlay {
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.9) !important;
}

.burgerButton {
  position: relative;
  z-index: 1 !important;
}

.menu {
  overflow: hidden !important;
}
.menuWrap {
    left: 0;
}

.menuMobile {
    
  left: 0;
}

.crossButton {
  width: 35px !important;
  height: 35px !important;
}

.itemList{
    margin: 25px 0 !important;
    font-size: 24px !important;
    text-transform: uppercase;
    color:#a5a5a5;
    cursor: pointer;
    padding-left: 20px;
}

