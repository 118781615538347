.bannerContainer {
  background: url(../../../../../assets/background.svg) no-repeat center center;
  background-size: cover;
  margin-top: 99px;
  padding: 7% 17%;
  background-attachment: fixed;
}


.bannerTextContainer {
  color: white;
  text-align: center;
}

.bannerText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  display: block;
  margin-bottom: 38px;

}

.bannerTextContainer span {
  color: #ca4145;
  font-weight: 600;

  font-style: normal;
}

.bannerTextContainer h1 {
  margin-top: 0;
  font-size: 2.5rem;
}

@media only screen and (max-width: 992px) {
  .bannerContainer {
    padding: 7% 9%;
  }
}
