

body {
    font-family: "Roboto Slab";
    background-color: #0A0A0A;
    color: white;
   
}

html{
    font-size: 100%;
}

@media only screen and (max-width: 600px) {
    html{
        font-size: 65%;
    }
}

