.bannersContainer {
  display: flex;
  flex-direction: row;
  align-content: center;
}

@media only screen and (max-width: 599px) {
  .bannersContainer {
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;
    align-content: center;
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .bannersContainer {
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;
    align-content: center;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .bannersContainer {
    flex-direction: column;
    flex-wrap: wrap;
    margin: 0;
    align-content: center;
  }
}

@media only screen and (min-width: 993px) and (min-width: 1200px) {
  .bannersContainer {
    display: flex;
    flex-direction: column;
    width: auto;
    flex-wrap: wrap;
  }
}
@media only screen and (min-width: 1200px) {
    .bannersContainer {
        display: flex;
        flex-direction: row;
        align-content: center;
        flex-wrap: nowrap;
      }
}
