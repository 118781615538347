.galleryContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;

}

.name {
  display: none;
  position: absolute;
  top: calc(50% - 40px);
  width: 100%;
  text-align: center;
  font-weight: 400;
  font-size: 2rem;

}
.name::after{
    content: '';
    display: block;
    width: 30%;
    height: 1px;
    background-color: #e2dfdf;
    margin: 0 auto;
    margin-top: 5px;
}

.itemGallery:hover .name {
  display: block;
}

.itemGallery:hover .itemGalleryImage {
  transform: scale(1.1);
  transition: 0.5s;
  background-color: rgba(0, 0, 0, 0.478);
  opacity: 0.5;
  
}

.itemGallery {
  position: relative;
  cursor: pointer;

}


.itemGalleryImage{
    min-width: 360px;
  min-height:471px;
  background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-block;
}