.bannerInfoBtn {
  padding: 4%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border: 1px solid #963033;
  margin: 0 1em;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  left: -110%;
  z-index: 1;
}

.boxAnimated {
  animation-name: slidein, fadein;
  animation-duration: 1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes slidein {
  from {
    left: -100%;
  }
  to {
    left: 0;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.bannerInfoBtn p {
  flex: 1 0 auto;
}

.bannerInfoBtn div {
  margin: 200px auto 0 auto;
}

.bannerInfoBtnTitle {
  font-weight: 400;
  font-size: 30px;
  margin-top: 0;
}

.bannerInfoBtnSubtitle {
  font-weight: 100;
  font-size: 16px;
}

@media only screen and (max-width: 992px) {
  .bannerInfoBtn div {
    margin: 20px auto 0 auto !important;
  }
  .bannerInfoBtn {
    padding: 10%;
  }
}

@media only screen and (max-width: 1200px) {
  .bannerInfoBtn {
    margin: 20px 0;
    width: auto;
  }

  .bannerInfoBtn div {
    margin: 100px auto 0 auto;
  }
  .bannerInfoBtnTitle {
    margin: 0px 0 8px 0;
  }
}
