.sectionHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5% 17% 4% 17%;
  text-align: center;

}

.sectionHeaderTitle {
  font-style: normal;
  font-weight: 400;
  font-size: 3.5rem;
  margin: 0;
}

.sectionHeaderSubtitle {
  font-weight: 300;
  font-size: 16px;
  color: #b9b6b6;
}



@media only screen and (max-width: 599px) {
    .sectionHeaderTitle {
      
        margin: 15px 0;
      }
      .responsive{
        padding: 0 7% 4% 7%;
      }
    
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
    .sectionHeaderTitle {
      
        margin: 15px 0;
      }
      .responsive{
        padding: 0 7% 4% 7% ;
      }
    
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .sectionHeaderTitle {
      
        margin: 15px 0;
      }
   
      .responsive{
        padding: 0 7% 4% 7% ;
      }
}

@media only screen and (min-width: 993px) and (max-width: 1200px) {
    .sectionHeaderTitle {
      
        margin: 15px 0;
      }
   

}
@media only screen and (min-width: 1200px) {

}
