.contactUsContainer{
    background: #161616;
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding-bottom: 7rem;
}

.formContainer{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 70%;
    margin: 0 auto;
}


@media only screen and (max-width: 992px) {

    .formContainer{
        flex-direction: column-reverse;
        width: 100%;
    }

    .contactUsContainer{

        padding: 0 20px;
    }
    
}